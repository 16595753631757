import { BigDaddyProvider } from './Provider/BigDaddyDeveloperContext';
import "./flow/config";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BigDaddyDeveloperComponent from './Components/BigDaddyDeveloperComponent';
import BigDaddyDeveloperNavBar from './Components/BigDaddyDeveloperNavBar';

function App() {
  return (
    <BrowserRouter>
      <BigDaddyProvider>
        <div className="BigDaddyMarketplaceContainer">
          <BigDaddyDeveloperNavBar />
          <Routes>
            <Route path="/" element={<BigDaddyDeveloperComponent />} />
          </Routes>
        </div>
      </BigDaddyProvider>
    </BrowserRouter>
  );
}

export default App;
