// BigDaddyContext.js

import { useState, createContext, useContext, useEffect } from 'react';
import BigDaddyDeveloperTransactions from '../flow/BigDaddyDeveloperTransactions';
import BigDaddyDeveloperScripts from '../flow/BigDaddyDeveloperScripts';

const BigDaddyDeveloperContext = createContext();

export function useBigDaddyDeveloperContext() {
  return useContext(BigDaddyDeveloperContext);
}

export function BigDaddyProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCollectionEnabled, setIsCollectionEnabled] = useState(false);
  const [nftTemplate, setNFTTemplate] = useState(null);
  const [user, setUser] = useState(null);
  const [isBigDaddyLoading, setIsBigDaddyLoading] = useState(false);
  const [isBigDaddyErrorModalOpen, setIsBigDaddyErrorModalOpen] = useState(false);
  const [bigDaddyErrorMessage, setBigDaddyErrorMessage] = useState("");
  const [flowBalance, setFlowBalance] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);


  const bigDaddyScripts = new BigDaddyDeveloperScripts();
  const bigDaddyTransactions = new BigDaddyDeveloperTransactions();

  useEffect(() => {
    if (user) {
      hasBigDaddyCollection();
      getBigDaddyTemplate();
      getBalance();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const validateLoggedIn = (myuser) => {

  if (myuser && (!user || myuser.cid !== user.cid)) {
      setUser(myuser);
      setIsLoggedIn(true);
    }
  };

  const disconnect = () => {
    if (user !== null) {
    setUser(null);
    setIsLoggedIn(false);
    }
  };

  const hasBigDaddyCollection = async () => {
    setIsBigDaddyLoading(true);
    try {
      const collectionExists = await bigDaddyScripts.hasBigDaddyCollection(user.addr);
      setIsCollectionEnabled(collectionExists);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const getBalance = async () => {
    setIsBigDaddyLoading(true);
    try {
      const usdcBalance = await bigDaddyScripts.getUSDCBalance(user.addr);
      const flowBalance = await bigDaddyScripts.getFLOWBalance(user.addr);
      setUsdcBalance(usdcBalance);
      setFlowBalance(flowBalance);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const getBigDaddyTemplate = async () => {
    setIsBigDaddyLoading(true);
    try {
      const template = await bigDaddyScripts.getTemplatebyAuthor(user.addr);
      setNFTTemplate(template);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const handleActivateBigDaddyCollection = async (password, email) => {
    setIsBigDaddyLoading(true);
   
    try {
      await createGitlabAccount(email, user, password)
      await bigDaddyTransactions.enableBigDaddyCollection();
      setIsCollectionEnabled(hasBigDaddyCollection);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const handleCreateNewBigDaddyNFTTemplate = async (name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.CreateNewBigDaddyNFTTemplate(name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) 
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      getBigDaddyTemplate();
      setIsBigDaddyLoading(false);
    }
  };

  const handleUpdateBigDaddyNFTTemplate = async (id, name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.UpdateBigDaddyNFTTemplate(id, name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) 
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      getBigDaddyTemplate();
      setIsBigDaddyLoading(false);
    }
  };

  const closeBigDaddyErrorModal = () => {
    setIsBigDaddyErrorModalOpen(false);
    setBigDaddyErrorMessage("");
  };

  const createGitlabAccount = async (email, user, password) => {
     //TODO: move base_url in .env
     const response = await fetch('https://us-central1-bigdaddynft.cloudfunctions.net/createGitLabAccount', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        name:  user.addr,
        username: user.addr,
        skip_confirmation: 1,
        password: password,
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to create GitLab account');
    }
  }

  return (
    <BigDaddyDeveloperContext.Provider value={{ bigDaddyErrorMessage, 
                                            isBigDaddyLoading, 
                                            isBigDaddyErrorModalOpen, 
                                            isLoggedIn, 
                                            isCollectionEnabled,
                                            nftTemplate, 
                                            user,
                                            flowBalance,
                                            usdcBalance,
                                            validateLoggedIn, 
                                            disconnect,
                                            handleCreateNewBigDaddyNFTTemplate, 
                                            handleUpdateBigDaddyNFTTemplate,
                                            handleActivateBigDaddyCollection, 
                                            closeBigDaddyErrorModal}}>
      {children}
    </BigDaddyDeveloperContext.Provider>
  );
}
