import React from "react";
import '../BigDaddyCSS.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import { useBigDaddyDeveloperContext } from '../Provider/BigDaddyDeveloperContext';
import {
    CNavbar,
    CNavbarBrand,
    CContainer,
    CDropdownDivider,
    CDropdown, CDropdownToggle, CDropdownMenu
} from '@coreui/react';
import * as fcl from "@onflow/fcl";


function BigDaddyDeveloperNavBar() {
    const { isLoggedIn, user, flowBalance, usdcBalance, } = useBigDaddyDeveloperContext();
   

    const handleLogOut = () => {
        fcl.unauthenticate();
      };

    const dropdownstyle = {
        '--cui-btn-bg': '#ab069d',
        '--cui-btn-hover-bg': '#510e7c'
    }

    return (

        <CNavbar colorScheme="light" className="bg-light d-flex justify-content-between">
            <CContainer fluid>
                <div className="d-flex justify-content-start">
                        {isLoggedIn && (
                            <CDropdown >
                                <CDropdownToggle style={dropdownstyle} >My Account</CDropdownToggle>
                                <CDropdownMenu>
                                    <p style={{ paddingLeft: '5px', color: '#47013e' }}>Wallet address: {user.addr}</p>
                                    <p style={{ paddingLeft: '5px', paddingTop: '5px',color: '#47013e' }}>FLOW Balance: {parseFloat(flowBalance).toFixed(3)} FLOW</p>
                                    <p style={{ paddingLeft: '5px', paddingTop: '5px',color: '#47013e' }}>USDC Balance: {parseFloat(usdcBalance).toFixed(3)} USDC</p>
                                    <CDropdownDivider />
                                    <button onClick={handleLogOut} className="bigdaddy-button">Log Out</button>
                                </CDropdownMenu>
                            </CDropdown>
                        )}
                </div>
                <div className="d-flex justify-content-center flex-grow-1">
                    <CNavbarBrand href="#">
                        <img
                            src={"/bdndevlogo.png"}
                            alt=""
                            width="420"
                            height="80"
                            className="d-inline-block align-center"
                        />
                    </CNavbarBrand>
                </div>
            </CContainer>
        </CNavbar>
    );
}

export default BigDaddyDeveloperNavBar;