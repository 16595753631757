export const UPDATE_NFT_TEMPLATE_TX = `import BigDaddyMarketplaceContract from 0xe3fc00107f99cc50

transaction(templateId: UInt32, name: String, price: UFix64, projectId: String, description: String, miniatureURL: String, picture1Url: String, picture2Url: String, picture3Url: String, videoUrl: String, creatorwebsiteUrl: String, demowebsiteUrl: String, token: String) {
 
  //let minterReference: &BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.MinterCollectionPublic}

  prepare(acct: AuthAccount) {
    let minterReference = acct.borrow<&BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.MinterCollectionPublic}>(from: BigDaddyMarketplaceContract.NFTMarketplaceCollectionStoragePath) 
        ?? panic("Cannot borrow")
        minterReference.updateBigDaddyMarketplaceNFTTemplate(templateId: templateId, name: name, price: price, projectId: projectId, description: description, miniatureURL: miniatureURL, picture1Url: picture1Url, picture2Url: picture2Url, picture3Url: picture3Url, videoUrl: videoUrl, ownerwebsiteUrl: creatorwebsiteUrl , demowebsiteUrl: demowebsiteUrl, token: token)
  }
}`;