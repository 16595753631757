import React, { useState, useEffect } from 'react';
import '../BigDaddyCSS.css';
import { CFormInput, CContainer, CFormLabel, CRow, CCol, CFormTextarea, CFormCheck } from '@coreui/react';
import { useBigDaddyDeveloperContext } from '../Provider/BigDaddyDeveloperContext';



function BigDaddyDeveloperPage() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [projectId, setProjectId] = useState("");
  const [description, setDescription] = useState("");
  const [miniatureURL, setMiniatureURL] = useState("");
  const [picture1Url, setPicture1Url] = useState("");
  const [picture2Url, setPicture2Url] = useState("");
  const [picture3Url, setPicture3Url] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [creatorwebsiteUrl, setCreatorwebsiteUrl] = useState("");
  const [demowebsiteUrl, setDemowebsiteUrl] = useState("");
  const [selectedToken, setSelectedToken] = useState('FLOW');

  const {handleCreateNewBigDaddyNFTTemplate, nftTemplate, handleUpdateBigDaddyNFTTemplate } = useBigDaddyDeveloperContext();

  useEffect(() => {
    if (nftTemplate != null) {
      setName(nftTemplate.name);
      setPrice(nftTemplate.price);
      setProjectId(nftTemplate.projectId);
      setDescription(nftTemplate.description);
      setMiniatureURL(nftTemplate.miniatureURL);
      setPicture1Url(nftTemplate.picture1Url);
      setPicture2Url(nftTemplate.picture2Url);
      setPicture3Url(nftTemplate.picture3Url);
      setVideoUrl(nftTemplate.videoUrl);
      setCreatorwebsiteUrl(nftTemplate.ownerwebsiteUrl);
      setDemowebsiteUrl(nftTemplate.demowebsiteUrl);
      setSelectedToken(nftTemplate.token)
    }
  }, [nftTemplate]);

  const createTemplate = () => {
    let finalPrice = price;
    if(Number.isInteger(Number(price))) {
      finalPrice = parseFloat(price).toFixed(1);
    }
    handleCreateNewBigDaddyNFTTemplate(name, finalPrice, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, selectedToken) ;
  };

  const updateTemplate = () => {
    let finalPrice = price;
    if(Number.isInteger(Number(price))) {
      finalPrice = parseFloat(price).toFixed(1);
    }
    handleUpdateBigDaddyNFTTemplate(nftTemplate.templateID, name, finalPrice, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, selectedToken) ;
  };


  return (
      <>
     <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h1 style={{ margin: '40px', color: 'white'}}>Your Website Template</h1>
      </div>
     <CContainer>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Name</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={name} onChange={e => setName(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Price</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="number" step="0.01" value={price} onChange={e => setPrice(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>ProjectId</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={projectId} onChange={e => setProjectId(e.target.value)} disabled={nftTemplate !== null}/>
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Description</CFormLabel>
            <CCol sm={10} className="mx-3">
              <CFormTextarea value={description} onChange={e => setDescription(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Miniature URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={miniatureURL} onChange={e => setMiniatureURL(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>First Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture1Url} onChange={e => setPicture1Url(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Second Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture2Url} onChange={e => setPicture2Url(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Third Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture3Url} onChange={e => setPicture3Url(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Video URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={videoUrl} onChange={e => setVideoUrl(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Creator Website URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={creatorwebsiteUrl} onChange={e => setCreatorwebsiteUrl(e.target.value)}/>
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'white'}}>Demo Website URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={demowebsiteUrl} onChange={e => setDemowebsiteUrl(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'white' }}>Choose the token you want to use to get paid:</CFormLabel>
              <CCol sm={10} className="mx-3">
              <CFormCheck type="radio" name="tokenRadio" id="FLOW" label="FLOW" 
                  value="FLOW"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'FLOW'}
              />
              <CFormCheck type="radio" name="tokenRadio" id="USDC" label="USDC"
                  value="USDC"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'USDC'}
              />
              </CCol>
            </CCol>
            
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            {nftTemplate === null ? (<button onClick={createTemplate} className="bigdaddy-button" style={{ margin: '40px 0px 0px 40px'}}>Create my Website Template</button>) : (<button onClick={updateTemplate} className="bigdaddy-button" style={{ margin: '40px 0px 0px 40px'}}>Update my Website Template</button>)}
            </CCol>
          </CRow>
        </CContainer>


    </>

  );
}

export default BigDaddyDeveloperPage;
