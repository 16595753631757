import React, { useState } from "react";
import '../BigDaddyCSS.css';
import { CRow, CContainer, CCol,CFormLabel, CFormInput } from '@coreui/react';
import { useBigDaddyDeveloperContext } from '../Provider/BigDaddyDeveloperContext';

function BigDaddyDeveloperActivateAccountPage() {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {handleActivateBigDaddyCollection } = useBigDaddyDeveloperContext();

  const isPasswordValid = () => {
    return password === confirmPassword && password.length > 7;
  }

  return (
    
      <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h1 style={{ margin: '50px', color: 'white'}}>Activate your Marketplace Collection</h1>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h2 style={{color: 'white'}}>In order to Create your Website Template you have to create a BigDaddy Developer Collection.</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h2 style={{color: 'white'}}>If it is not allready done, this will also create you a FLOW and USDC Wallet in order to receive payments.</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h2 style={{color: 'white'}}>Finally, it will activate your account on <a href="https://gitlab.bigdaddy-nft.com/">https://gitlab.bigdaddy-nft.com/</a>.</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
      <h2 style={{color: 'white'}}>Use you wallet address as identifier.</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
      <h2 style={{ margin: '0px 0px 50px 0px', color: 'white'}}>Type your future gitlab password 2 times and your email address in order to activate your Collection.</h2>
      </div>

      <CContainer >
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
            <CFormLabel className="col-sm-2 col-form-label" style={{color: 'white'}}>Email address:</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={email} onChange={e => setEmail(e.target.value)} />
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
            <CFormLabel className="col-sm-2 col-form-label" style={{color: 'white'}}>Password:</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="password" value={password} onChange={e => setPassword(e.target.value)} />
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
            <CFormLabel className="col-sm-2 col-form-label" style={{color: 'white'}}>Confirm Password:</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            </CCol>
          </CRow>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <button
          className="bigdaddy-button"
          onClick={() => handleActivateBigDaddyCollection(password, email)}
          disabled={!isPasswordValid()}
        >
          Activate BigDaddyCollection
        </button>
      </div>
      </CContainer>
      </>
  );
}

export default BigDaddyDeveloperActivateAccountPage;
